:root {
  --bodyColor: "#000000";
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bodyColor);
  background: var(--bodyColor);
  /* background: var(--bodyColor); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.primaryColor {
  background-color: #00cccc;
}
.input200W {
  /* width: 200px; */
  min-width: 200px;
}
.input100W {
  min-width: 100px;
}
.input60W {
  min-width: 60px;
}
.clickable {
  cursor: pointer;
}
.billAmountLabel {
  display: inline;
  font-weight: bold;
  font-size: x-large;
}

.billAmountValue {
  display: inline;
  font-weight: normal;
  font-size: x-large;
}
.date-picker-label{
  display: flex;
  flex-direction: column;
}
